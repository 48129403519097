import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/docs.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const Wizard = makeShortcode("Wizard");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Materia is the World first, User-Centric DEX governed by On-Chain, Enterprise-Free Organization (`}<a parentName="p" {...{
        "href": "https://www.dfohub.com/"
      }}><inlineCode parentName="a">{`DFO`}</inlineCode></a>{`).
In the following pages is contained the full documentation of the Materia protocol. If you are new to Materia, you might want to check out our social or get started with the `}<Link to="/docs/materia/protocol-overview" mdxType="Link">{`Protocol Overview`}</Link>{`.`}</p>
    <h2 {...{
      "id": "key-feature",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#key-feature",
        "aria-label": "key feature permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Key feature`}</h2>
    <p>{`Taking advantage of a microservices architecture Materia aims to scale over time in order to implement new features and granting security by design in an environment where immutability reigns supreme.
We base our protocol on the following pillars:`}</p>
    <ul>
      <li parentName="ul">{`WUSD: a Stablecoin based on Liquidity Pools`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://ethitem.com/"
        }}>{`EthItem`}</a>{`: a synthesis of the ERC1155 & ERC20 standards, enabling their total interoperability.`}</li>
      <li parentName="ul">{`Microservices: real decentralized possibility of upgrading over time`}</li>
      <li parentName="ul">{`DFOs Governance: On-Chain companies with proprietary assets and voting tokens as programmable equities.`}</li>
    </ul>
    <h2 {...{
      "id": "getting-started",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#getting-started",
        "aria-label": "getting started permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Getting Started`}</h2>
    <Wizard mdxType="Wizard" />
    <h2 {...{
      "id": "developer-links",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#developer-links",
        "aria-label": "developer links permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Developer links`}</h2>
    <p>{`The Materia codebase is embody an ecosystem of open source components.`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/materia-dex/materiadex-com"
        }}><inlineCode parentName="a">{`Official Website of Materia`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/materia-dex/materia-interface"
        }}><inlineCode parentName="a">{`Materia Interface`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/materia-dex/materia-contracts"
        }}><inlineCode parentName="a">{`Materia Contracts`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/materia-dex/materia-sdk"
        }}><inlineCode parentName="a">{`Materia SDK`}</inlineCode></a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://github.com/materia-dex/materia-token-list"
        }}><inlineCode parentName="a">{`Materia Token List`}</inlineCode></a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      